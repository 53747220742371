<template>
  <!-- 消息通知 -->
  <div class="Notification" v-loading="loading">
    <div class="content">
      <!-- 具体内容 -->
      <div class="contentds">
        <!-- 资源交易 -->
        <div class="assembly">
          <div class="hui" v-if="this.system.length==0&&this.celebrity.length==0">暂时没有相关消息通知</div>
          <div class="ZYcommt">
            <!-- 资源审核 -->
            <div class="toped" v-if="
              this.system.length!=0">
              <img style="width: 28px; height: 28px" src="~@/assets/BiaoQz.png" />
              <span class="postion">系统提示</span>
            </div>
            <el-timeline>
              <el-timeline-item
                v-for="item in system"
                :key="item.id"
                color="#0084FF"
                class="tttop ZYJY"
              >
                <div>
                  <span class="just">
                    智荟网发现您的文章违反平台规则，并
                    <span class="red">
                      {{
                      item.state
                      }}
                    </span>了您的
                    <span style="color:blue">
                      《 {{
                      item.title
                      }}》
                    </span>
                    文章
                  </span>
                </div>
                <div>
                  <span class="justW">{{ item.creatime }}</span>
                  <span @click="deletes(item.id)" class="justW">删除</span>
                </div>
              </el-timeline-item>
            </el-timeline>
            <!-- 知识网红 -->
            <div class="toped" v-if="
              this.celebrity.length!=0">
              <img style="width: 28px; height: 28px" src="~@/assets/BiaoQz.png" />
              <span class="postion">知识网红</span>
            </div>
            <el-timeline>
              <el-timeline-item
                v-for="item in celebrity"
                :key="item.id"
                color="#0084FF"
                class="tttop ZYJY"
              >
                <div>
                  <span class="just">
                    您在平台信用等级已经达到20级，且产出了大量的知识成果，现已成为我们平台的“
                    <span style="color:red">知识网红</span>”
                  </span>
                </div>
                <div>
                  <span class="justW">{{ item.creatime }}</span>
                  <span @click="deletes(item.id)" class="justW">删除</span>
                </div>
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
      </div>
    </div>
    <div v-if="loading">
      <V-Loading></V-Loading>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import Loading from "@/views/wisdom-answer/index/team-response/Pop/Loading/index.vue";
import { DELETE, QUERYED, INSERT, UPDATE, QUERY } from "@/services/dao.js";
import {
  updateNews,
  formatDate,
  AcceptNum,
  AcceptNumTeam,
  showTimeFormat
} from "@/js/common/index.js";
import {
  formatZero,
  getTimeFormat,
  getTimeFormat_1
} from "@/js/common/Resoures";

export default {
  data() {
    return {
      loading: false, //是否显示正在加载中
      system: [], //资源审核
      celebrity: [], //知识网红
      userid: this.$store.state.userinfo.id
    };
  },
  created() {
    this.init();
    this.zswh();
  },
  components: {
    "V-Loading": Loading //正在加载中
  },
  methods: {
    // 初次加载
    async init() {
      this.system = [];
      this.loading = true;
      let data = await QUERYED(
        "post",
        "",
        "  XTMessage (where: {userid : {_eq: " +
          this.userid +
          "},type  : {_eq: 0}}) {id state title userid  type articleid  creatime}   XTMessage_aggregate {  aggregate {    count  }  }"
      );
      for (let item of data.data.XTMessage) {
        item.creatime = showTimeFormat(item.creatime);
      }
      this.system = data.data.XTMessage;
      this.loading = false;
    },
    // 知识网红
    async zswh() {
      this.celebrity = [];
      this.loading = true;
      let data = await QUERYED(
        "post",
        "",
        "  XTMessage (where: {userid : {_eq: " +
          this.userid +
          "},type  : {_eq: -1}}) {id state title userid  type articleid  creatime}   XTMessage_aggregate {  aggregate {    count  }  }"
      );

      this.celebrity = data.data.XTMessage;
      this.loading = false;
    },
    async deletes(id) {
      this.loading = true; //显示
      let data = await DELETE(
        "POST",
        "",
        " delete_XTMessage(where: {id: {_eq: " + id + "}}) {  affected_rows  }"
      ); ///删除消息通知
      if (data.data.delete_XTMessage.affected_rows == 1) {
        this.$message({
          type: "success",
          message: "删除成功！"
        });
        this.init();
        this.loading = false; //显示
      }
    }
  }
};
</script>
<style scoped>
.red {
  color: red;
  font-weight: bold;
}
.content_top {
  width: 100%;
  height: 30px;
  border-bottom: 1px solid rgba(125, 125, 125, 1);
}
.size {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: rgba(0, 0, 0, 1);
}
.size-right {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(103, 103, 103, 1);
  float: right;
  cursor: pointer;
  display: inline-block;
}
.content_center {
  width: 100%;
  height: 50px;
  margin-top: 20px;
  /* line-height: 50px; */
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 300;
  color: rgb(83, 83, 83);
}
.img {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-size: 15px 15px;
  background-image: url("~@/assets/bigLOGO.png");
  position: relative;
  top: 3px;
  left: -5px;
}
.demo1 {
  width: 100px;
  background: white;
  /* border:1px solid rgba(124,124,124,1); */
  border-radius: 5px;
  display: inline-block;
  position: relative;
  top: -10px;
  /* padding-top: 5px; */
  /* padding-bottom: 5px; */
  border: 1px solid rgba(219, 219, 219, 1);
  box-shadow: 1px 2px 13px 0px rgba(87, 87, 87, 0.44);
}
.demo1 > span {
  width: 100%;
  /* border: 1px solid red; */
  line-height: 30px;
  display: inline-block;
  text-align: center;
  cursor: pointer;
}

/*气泡框效果实现*/

.demo {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: white;
  /* border:1px solid rgba(124,124,124,1); */
  transform: rotate(50deg);
  position: relative;
  left: -40px;
  top: 5px;
  border: 1px solid rgba(219, 219, 219, 1);
  box-shadow: 1px 2px 13px 0px rgba(87, 87, 87, 0.44);
}
.right_rigth {
  width: 100%;
  text-align: right;
  display: inline-block;
  /* border: 1px solid red; */
}
.wid {
  width: 100%;
  text-align: right;
  display: inline-block;
}
.imges {
  background-image: url("~@/assets/icon/down1.png");
  display: inline-block;
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  position: relative;
  top: 10px;
}
.demo1 > span:hover {
  color: #0084ff;
}
.classification {
  color: #0084ff;
  /* font-weight: bold; */
  /* cursor:pointer */
}
.blue {
  color: #0084ff;
  cursor: pointer;
  text-decoration: underline;
}
/* 组件 */
.contentds {
  width: 100%;
  /* border: 1px solid rebeccapurple; */
}
.assembly {
  width: 100%;
  /* border: 1px solid darkgoldenrod; */
}
.assembly_top {
  width: 100%;
  height: 30px;
  line-height: 30px;
  font-size: 15px;
}

.ZYJY {
  width: 100%;
  /* height: 30px; */
  line-height: 30px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
}
/* .TTcommt>div :hover,.TTCollection>div :hover,.ZNCollection>div :hover,.ZNcommt>div :hover,.ZNinvitation>div :hover,.ZNnotice>div :hover,.ZYcommt>div :hover,.ZYCollection>div :hover{
    background-color: bisque;
    display: inline-block;
} */
.tttop:hover {
  cursor: pointer;
  background-color: #e4e4e4;
  border-radius: 5px;
  border: 1px solid rgba(219, 219, 219, 1);
  box-shadow: 1px 2px 13px 0px rgba(219, 219, 219, 0.44);
}
.name {
  font-weight: bold;
  margin-right: 10px;
  /* margin-left: 10px; */
}
.just {
  /* margin-left: 10px; */
  color: #000000;
}
.td {
  margin-right: 10px;
}
.tu {
  width: 3px;
  height: 3px;
  background: rgba(0, 132, 255, 1);
  border-radius: 80%;
  display: inline-block;
  margin-right: 3px;
}
.cha {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-repeat: no-repeat;
  background-image: url("~@/assets/icon/fork.png");
  position: relative;
  top: 8px;
  left: 5px;
  cursor: pointer;
}
.tops {
  font-size: 13px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #0084ff;
  margin-top: 10px;
}
.toped {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #000000;
  /* border: 1px solid red; */
  height: 40px;
}
.people {
  width: 95%;
  border: 1px solid #d6d6d6;
  padding: 1%;
  margin: auto;
  border-radius: 5px;
}
.feed {
  display: inline-block;
  word-wrap: break-word;
  word-break: break-all;
  /* 超出范围后换行 */
  white-space: pre-wrap;
}
::v-deep .el-timeline-item__tail {
  border-left: 1px dashed #a6beec;
}
::v-deep .el-timeline-item {
  padding-bottom: 1px;
}
.justW {
  /* margin-left: 10px; */
  color: #006cff;
  margin-right: 100px;
}
.postion {
  position: relative;
  top: -8px;
  margin-left: 5px;
}
.hui {
  color: #979797;
  font-size: 17px;
  text-align: center;
}
</style>
